import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import OrderReschedule from "../components/order-reschedule";

interface OrderConfirmationPageProps {
  location: Location;
}

const IndexPage: FC<OrderConfirmationPageProps> = ({
  location,
}) => (
  <Layout header menuState="profile" requireAuth>
    <SEO title="Accueil" />
    <OrderReschedule location={location} />
  </Layout>
);

export default IndexPage;
